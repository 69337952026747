.nav-bar__product-search {
    margin-left: 18rem;
}

.nav-bar__product-search__input {
    width: 100%;
    min-width: 65rem;
    border: 0.1rem solid var(--aqua);
    color: var(--black);
    background: var(--white);
    font-size: 1.6rem;
    margin-right: 1.6rem;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 600;
    line-height: 3.2rem;
    padding: 0.8rem 0;
    border-radius: 0.8rem;
    text-align: center;
    cursor: pointer;
    user-select: none;
    transition: background 0.3s ease-out, color 0.3s ease-out;
}